:root {
    --body_bg : #0B0909 ;
    --searchbar_bg : #141313;
    --placeholder : #7E7E89;
    --white : #fff;
    --black : #000;
    --header_switchbg : #231507;
    --header_border : #E5E5E5;
    --gold : #5fe44b;
    --dash_box : #161414;
    --green : #43D96F;
    --orangeBtn_bg : #2E1E13;
    --grad_blue : #103252;
    --grad_green : #73CC2A;
    --grad_ink : #322BF2;
    --grad_darkgreen : #013E5A;
    --table_green : #00CE6A;
    --table_green_btn : #1F7700;
    --table_red_btn : #FF1616;
    --link : #1492E6;
    --radiant: #4cd5d9;
  --radiant_green: #5fe44b;
    --box_border : #232323;
    --small_font : 8px;
    --lower_font : 10px;
    --font_twelve : 12px;
    --font_thirteen : 13px;
    --font_fifteen : 15px;
    --font_sixteen : 16px;
    --font_eighteen : 18px;
    --font_twenty : 20px;
    --font_twentyfive : 25px;
    --font_thirty : 30px;
    --font_thirtyfive : 35px;
    --higher_font : 40px;
    --weight_six: 600;
    --weight_seven: 700;
    --weight_five: 500;
    --weight_three: 300;
    --weight_four: 400;
    --weight_eight: 800;
    --weight_nine: 900;
    --satoshi_font : "satoshi";
}
/* font import */
@font-face {
  font-family: "satoshi_bold";
  src: url("../fonts/Satoshi-Bold.otf");
}
@font-face {
  font-family: "satoshi";
  src: url("../fonts/Satoshi-Regular.otf");
}
/* common css */
.common_bg,body{
    background-color: var(--body_bg);
    overflow: hidden;
}
.liner{
    border-bottom: 1px solid;
    position: absolute;
    top: 80px;
    width: 100%;
}
.apexcharts-grid-borders,.apexcharts-xaxis-tick{
    display: none;
}
.orange_btn{
    border: 1px solid var(--orangeBtn_bg);
    outline: none;
    background-color: var(--orangeBtn_bg);
    color: var(--gold);
    padding: 5px 20px;
    border-radius: 30px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
    min-width: 100px;
}
.orange_btn:hover{
    background: transparent;
    color: var(--gold);
}
.orange_primary_btn{
    border: 1px solid var(--gold);
    outline: none;
    background: linear-gradient(to right,var(--gold),var(--radiant));
    color: var(--black);
    padding: 8px 20px;
    border-radius: 5px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
    min-width: 100px;
    cursor: pointer;
}
.orange_primary_btn:hover{
    background: transparent;
    color: var(--gold);

}
.orange_small_primary{
    border: 1px solid var(--gold);
    outline: none;
    background: linear-gradient(to right,var(--gold),var(--radiant));
    color: var(--black);
    padding: 5px 20px;
    border-radius: 5px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
    cursor: pointer;
    width: fit-content;
}
.orange_small_primary:hover{
    background: transparent;
    color: var(--gold);

}
.secondary_btn{
    border: 1px solid var(--gold);
    outline: none;
    background-color: transparent;
    color: var(--gold);
    padding: 8px 20px;
    border-radius: 5px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
    min-width: 100px;
    cursor: pointer;
}
.active_primary_btn{
    border: 1px solid var(--gold);
    outline: none;
    background-color: var(--gold);
    color: var(--black);
    padding: 8px 20px;
    border-radius: 5px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
    min-width: 100px;
    cursor: pointer;
}
.common_page_scroller{
    max-height: 80vh;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    animation: dizzle .5s ease;
}
.profile_holder{
    animation: dizzle .5s ease;
}
@keyframes dizzle {
    0%{
        opacity:0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
    
}
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--dash_box);
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--gold); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--gold); ; 
  }
  .cmn_plain_btn{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }
  .table_activity_img{
    height: 15px;
    width: 15px;
  }
  .cmn__navLinks{
    text-decoration: none;
    color: var(--white);
    border: none;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--black);
    -webkit-transition: .4s;
    transition: .4s;
    border: none;
    box-shadow: none;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--radiant_green);
  }
  
  input:focus + .slider {
    box-shadow: none;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: var(--black);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .pagination .page-item,.pagination .page-link{
    background-color: var(--black) !important;
    border-color: var(--box_border);
    color: var(--white);
  }
  .table_extrabtns{
    border: 1px solid var(--gold);
    outline: none;
    background: linear-gradient(to right,var(--gold),var(--radiant));
    color: var(--black);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: var(--font_eighteen);
    font-weight: var(--weight_six);
    cursor: pointer;
    width: fit-content;
  }
  .cmn_extraBtnsLabel{
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
  }
/* end of common css */
/* header css */
.header{
    min-height: 80px;
}
.header_title{
    margin: 0;
    color: var(--white);
    font-size: var(--font_sixteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
}
.header_searchbar{
    background-color: var(--searchbar_bg);
}
.header_searchbar_input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_five);
}
.header_dragger{
    background-color: var(--header_switchbg);
    height: 40px;
    cursor: pointer;
    min-width: 150px;
    max-width: 150px;
}
.header_switchThunder{
    height: 30px;
    width: 30px;
    object-fit: contain;
    position: absolute;
    top: 13%;
    transition: 1s;
}
.header_thunderLeft{
    left: 5%;
}

.header_thunderRight{
    right: 5%;
}
.header_switchHint{
    margin: 0;
    font-size: var(--font_twelve);
    color: var(--white);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}
.header_profileImg{
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    object-fit: contain;
}
.header_profile_drop button,.header_profile_drop button:hover,.header_profile_drop button:active,.header_profile_drop button:focus,.header_profile_drop button:focus-visible,.header_profile_drop .btn-check:checked+.btn,.header_profile_drop .btn.active,.header_profile_drop .btn.show,.header_profile_drop .btn:first-child:active,.header_profile_drop :not(.btn-check)+.btn:active{
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;     
    width: fit-content;
}
.header_profile_drop button::after{
    display: none;
}
.header_profile_drop .dropdown-menu{
    background-color: var(--searchbar_bg);
}
.header_profile_drop .dropdown-item{
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}
.header_profile_drop .dropdown-item:hover{
    background-color: var(--gold);
    color: var(--black);
}

.header_canvas.offcanvas{
    background-color: var(--searchbar_bg);
}
.header_canva_header{
    border-bottom: 1px solid;
}
.header_canvas :is(ul,li) {
    list-style-type: none;
    padding-left: 0;
    cursor: pointer;
}
.mob_searchglass{
    height: 25px;
    width: 25px;
}
.mob_searchbar_holder{
    background-color: var(--searchbar_bg);
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
    animation: flipper .5s linear;
}
@keyframes flipper {
    0%{
        opacity: 0;
        transform: scale(0);
    }
    25%{
        opacity: 0.25;
        transform: scale(.25);
    }
    50%{
        opacity: .5;
        transform: scale(.5);
    }
    75%{
        opacity: .75;
        transform: scale(.75);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
.mob_searchbar_holder .mob_searchbar_inp{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}
.mob_searchbar_holder i{
    color: var(--white);
    font-size: var(--font_eighteen);
}

/* end of header css */
/* sidebar css */

.sidebar{
    width: 100%;
    height: 100vh;
    background-color: var(--searchbar_bg);
}
.sidebar ul,.sidebar li{
    list-style-type: none;
    padding-left: 0;
    cursor: pointer;
}
.sidebar .sidebar__scrollUl{
    max-height: 76vh !important;
    overflow-y: auto !important;
}
.sidebar_link_hint{
    margin: 0;
    color: var(--white);
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
}
.sidebar_links{
    text-decoration: none;
}
.sidebar_links.active,.sidebar_links.active .sidebar_link_hint{
    background-color: var(--gold);
    color: var(--black);
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}
.sidebar_links.active .sidebar_linkImg{
    filter: invert(1);
}
.sidebar li{
    max-width: 90%;
    min-width: 90%;
}
.sidebar li:hover{
    background-color: var(--gold);
    color: var(--black);
}
.sidebar li:hover .sidebar_link_hint{
    color: var(--black);
}
.sidebar li:hover .sidebar_linkImg{
    filter: invert(1);
}
.sidebar_logo_holder{
    min-height: 81px;
}
/* end of sidebar css */
/* dashboard css */
.dashboard_box{
    border: 1px solid var(--box_border);
    background-color: var(--dash_box);
}
.dash_lottie,.dash_lottie_holder{
    height: 45px;
    width: 45px;
}
.dash_logoImg{
    height: 40px;
    width: 40px;
}
.dash_coinImg{
    height: 30px;
    width: 30px;
    position: absolute;
    top: 17%;
    left: 16%;
}
.dash_greentext{
    margin: 0;
    color: var(--green);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}
.dash_graymed_text{
    margin: 0;
    color: var(--placeholder);
    font-size: var(--font_thirteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
}
.dash_graybig_text{
    margin: 0;
    color: var(--placeholder);
    font-size: var(--font_sixteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
}
.dash_valuewhite_text{
    margin: 0;
    color: var(--white);
    font-size: var(--font_eighteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_seven);
}
.dash_select_lottie_holder,.dash_select_lottie{
    position: absolute;
    height: 40px;
    height: 40px;
}
.dash_select_coinImg{
    height: 25px;
    width: 25px;
    top: 20%;
    left: 24%;
}
.market_select_component{
    background-color: var(--searchbar_bg);
    color: var(--white);
    cursor: pointer;
    font-size: var(--font_thirteen);
    font-family: var(--satoshi_font);

}
.dash_table_status{
    margin: 0;
    color: var(--white);
    font-family: var(--satoshi_font);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}
.dash_datatable_holder{
    height: fit-content;
    max-height: 86vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.dash_datatable .as-react-table table,.dash_datatable .table-bordered>:not(caption)>*,.dash_datatable .table>:not(caption)>*>*{
    background-color: transparent !important;
    border-color: transparent !important;
    --bs-table-border-color:transparent !important;
}
.dash_datatable .as-react-table thead{
    display: none !important;
}
.dash_table_arrowImg{
    height: 40px;
    width: 40px;
}
.dash_table_title{
    margin: 0;
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}
.dash_graph_btn{
    border: none;
    outline: none;
    background-color: transparent;
    padding: 5px 8px;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_fonts);
}
.dash_graph_btn.active,.dash_graph_btn:hover{
    background-color: var(--searchbar_bg);
}
/* end of dashboard css */

/* reset password css */
.rp_input_holder{
    border: 1px solid ;
}
.rp_input_holder i{
    color: var(--placeholder);
    cursor: pointer;
}
.rp_singleInput{
    border: none;
    outline: none;
    background-color: transparent;
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
    color: var(--white);
    font-family: var(--satoshi_font);
    
}
.rp_label{
    margin: 0;
    color: var(--white);
    font-size: var(--font_sixteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}
/* end of reset password css */

/* exchange css */
.exchange_table_holder .asrt-table-body,.table.table-bordered.table-striped{
    margin: 0 !important;
    overflow-x: auto;

}
.exchange_table_holder .col-md-12{
    padding: 0;
}
.exchange_table_holder .table-bordered>:not(caption)>*{
    border-width: 0 !important;
}
/* .exchange_table_holder .table tbody>:not(caption)>*>*{
    background-color: transparent !important;
} */
.exchange_table_holder thead{
}
.exchange_table_holder .table>:not(caption)>*>*{
    background-color: transparent !important;
    border-color: transparent;
    color: var(--placeholder);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
    padding: 11px 0px;
    min-width: 130px;
    max-width: 130px;

}
.exchange_table_values{
    margin: 0;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
    font-family: var(--satoshi_font);
}
.exchange_table_address.exchange_table_values{
    text-decoration: underline;
    color: var(--link);
    cursor: pointer;
}
.exchange_activity{
    margin: 0;
    color: var(--gold);
    font-size: var(--font_fifteen);
    cursor: pointer;
}
.exchange_completed{
    border: none;
    outline: none;
    background-color: var(--table_green_btn);
    padding: 5px 15px;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
    min-width: 100px;
}
.exchange_pending{
    border: none;
    outline: none;
    background-color: var(--gold);
    padding: 5px 15px;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
    min-width: 100px;
}
.exchange_fail{
    border: none;
    outline: none;
    background-color: var(--table_red_btn);
    padding: 5px 15px;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
    min-width: 100px;
}
.exchange_table_holder .float-right.text-right{
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}
.exchange_table_holder .float-right.text-right input{
    background-color: var(--black);
    color: var(--white);
    font-size: var(--font_twelve);
    font-weight: var(--weight_five);
    box-shadow: none !important;
    border-color: var(--box_border);
}
.table_extrabtns input{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    opacity: 0;
}
.exchange_tableFileUploader{
    font-size: var(--font_fifteen);
}


/* end of exchange css */
/* subscription css */
.subscription_card_greenHead{
    background-image: linear-gradient(to right,var(--grad_blue),var(--grad_green));
}
.subscription_card_blueHead{
    background: linear-gradient(to right,var(--grad_ink),var(--grad_darkgreen));
}
.subscription_card{
    background-color: var(--searchbar_bg);
}
.subsciption_title{
    color: var(--white);
    margin: 0;
    font-size: var(--font_twenty);
    font-weight: var(--weight_eight);
    font-family: var(--satoshi_font);
    letter-spacing: 1px;
}
.subsciption_price_days{
    color: var(--white);
    margin: 0;
    font-size: var(--font_sixteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
    letter-spacing: 1px;
}
.subsciption_price_days small{
    font-size: var(--font_twelve);
    font-weight: var(--weight_five);
}
.subscriptioc_card_body :is(ul,li){
    list-style-type: none;
    padding-left: 0;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
}
.subsciption_green_gradBtn{
    border: none;
    outline: none;
    padding: 9px 15px;
    color: var(--white);
    background: linear-gradient(to right,var(--grad_blue),var(--grad_green));
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
    letter-spacing: .5px;
}
.subsciption_green_gradBtn:hover{
    background: linear-gradient(to right, var(--grad_green), var(--grad_blue));
}
.subsciption_blue_gradBtn{
    border: none;
    outline: none;
    padding: 9px 15px;
    color: var(--white);
    background: linear-gradient(to right,var(--grad_ink),var(--grad_darkgreen));
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
    letter-spacing: .5px;
}
.subsciption_blue_gradBtn:hover{
    color: var(--white);
    background: linear-gradient(to right, var(--grad_darkgreen) , var(--grad_ink));
}
.subscript_checkers{
    height: 20px;
    width: 20px;
}
.subsciption_table_greenText{
    margin: 0;
    color: var(--table_green);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
    font-family: var(--satoshi_font);
}
.subs_table_greenBtn{
    border: none;
    outline: none;
    background-color: var(--table_green_btn);
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
    padding: 5px 20px;
    min-width: 70px;
}
.subs_table_redBtn{
    border: none;
    outline: none;
    background-color: var(--table_red_btn);
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
    padding: 5px 20px;
    min-width: 70px;
}


/* end of subscription css */
/* modal css */
.cmn_modal .modal-content{
    background-color: var(--searchbar_bg);
    border: 1px solid;
    border-radius: 10px;
    
}
.cmn_modal_title{
    margin: 0;
    color: var(--white);
    font-size: var(--font_sixteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}
.cmn_modal_closer{
    border: 1px solid var(--gold);
    outline: none;
    background-color: var(--gold);
    color: var(--black);
    font-size: var(--font_sixteen);
    font-weight: var(--weight_five);
    padding: 0px 6px;
    cursor: pointer;
}
.ot_textarea{
    border: 1px solid #222222;
    outline: none;
    background-color: transparent;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
}
/* end of modal css */

/* trade11 css */
/* settings css */
.settings__holder{
    background-color: var(--searchbar_bg);
}
.orange_small_secondary{

    text-decoration: none;
        border: 1px solid var(--btnhover);
        outline: none;
        background-color: var(--btnhover);
        color: var(--gold);
        padding: 8px 20px;
        border-radius: 11px;
        font-size: var(--font_fifteen);
        font-family: var(--satoshi_font);
        font-weight: var(--weight_seven);
        cursor: pointer;
        width: fit-content;
         box-shadow: 0 4px 4px 0 #00000040;
    }
    .orange_small_secondary:hover{
        background: var(--gold) !important;
        border: 1px solid var(--gold) !important;
        color:var(--white);
    
    }
    .orange_small_secondary:disabled
{
    opacity: 0.5 !important;
    cursor: not-allowed;
}
.custom-file-upload {
    border: 1px solid var(--gold);
    border-radius: 0.375rem !important;
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    color:var(--gold);
    font-size: 14px;
  }
  .custom-file-upload:hover
  {
    border: 1px solid var(--gold);
    outline: none;
    color: var(--black);
    background-color: var(--gold);
  }
  input[type="file"].hide_input {
    display: none;
  }
  .cmnStatus__changer button{
    border: 1px solid var(--white);
    outline: none;
    background-color: transparent;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    padding: 5px 30px;
    box-shadow: none !important;
  }
  .cmnStatus__changer button::after{
    display: none;
  }
  .cmnStatus__changer button:hover,.cmnStatus__changer button:focus-visible,.cmnStatus__changer button:target,.cmnStatus__changer button:focus{
    background-color: transparent !important;
    border-color: var(--white) !important;
  }
  .cmnStatus__changer .dropdown-menu{
    background-color: var(--black);
  }
  .cmnStatus__changer .dropdown-item{
    background-color: transparent;
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    color: var(--white);
  }
  .cmnStatus__changer .dropdown-item:hover{
    background-color: var(--radiant_green);
    color: var(--black);
  }
  .cnctWallet__metamask{
    height: 60px;
    width: 60px;
  }
  .cmnModal__walletHolder{
    cursor: pointer;
  }
  .header__cnctWalletBtn{
    font-size: var(--font_thirteen);
    padding: 5px 10px;
  }
  .subs_add_btn{
    border: 1px solid var(--table_green);
    outline: none;
    background-color: var(--table_green);
    color: var(--white);
    font-size: var(--font_sixteen);
    font-weight: var(--weight_seven);
    padding: 5px 10px;
}
.subs_add_btn:hover{
    background-color: transparent;
    color: var(--table_green);
}
.subs_dlt_btn{
    border: 1px solid var(--table_red_btn);
    outline: none;
    background-color: var(--table_red_btn);
    color: var(--white);
    font-size: var(--font_sixteen);
    font-weight: var(--weight_seven);
    padding: 5px 10px;
}
.subs_dlt_btn:hover{
    background-color: transparent;
    color: var(--table_red_btn);
}
.cmn__siteLogo{
    
}
/* end of settings css */

/* login css */
.link_theme
{
    color:var(--white);
    font-size: 16px;
    font-family: var(--satoshi_font);

}
.link_theme:hover
{
    color:var(--radiant_green);
}
.header_title_big
{
    font-size: 20px;
    color: var(--white);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
}
.main_logo
{
    max-width: 180px;
}
.row_card{
    height: 100vh;
}

/* end of login css */
/* otp verification css */
.otp__holder input{
    background-color: transparent;
    outline: none;
    color: var(--white);
    font-size: var(--font_eighteen);
    font-weight: var(--weight_seven);
    border: 1px solid var(--box_border);
    height: 40px;
    width: 40px !important;
    margin-right: 10px;
}
/* end of otp verification css */
/* end of trade11 css */

/* media queries */
@media (width <= 575px){
    .liner{
        top: 60px;
    }
}
@media (width < 400px){
    .dash_table_arrowImg{
        height: 30px;
        width: 30px;
    }
    .header_profileImg{
        height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    object-fit: contain;
    }
}

/* end of media queries */